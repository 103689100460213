import { JwtPayload } from 'jwt-decode';
import { Action, Computed, Thunk } from 'easy-peasy';
import { User } from '../../api/apiTypes';
// eslint-disable-next-line import/no-cycle
import { StoreModel } from '../storeModel';

export type AuthorizedUser = {
  username: string;
  name: string;
  roles: UserRole[];
  partnerCode: string;
  partnerName: string;
};

export enum UserRole {
  SALESCONNECT_ADMIN = 'SALESCONNECT_ADMIN',
  PARTNER_ADMIN = 'PARTNER_ADMIN',
  PARTNER_WAREHOUSE_OPERATIONS = 'PARTNER_WAREHOUSE_OPERATIONS',
  PARTNER_USER = 'PARTNER_USER',
}

export enum Permission {
  RevertOrderStatus = 'revertOrderStatus',
  ProductImport = 'productImport',
  KauflandImport = 'kauflandImport',
  AllegroImport = 'allegroImport',
  LabelCreation = 'labelCreation',
  UpdateStocksAndPrices = 'updateStocksAndPrices',
  BatchUpdate = 'batchUpdate',
  Orders = 'orders',
  Shipments = 'shipments',
  Products = 'products',
  UserProfile = 'userProfiles',
  PartnerSetupOverview = 'partnerSetupOverview',
  PartnerSetup = 'partnerSetup',
  EshopSetupOverview = 'eshopSetupOverview',
  EshopSetup = 'eshopSetup',
  UserSetup = 'userSetup',
  UserSetupCreate = 'userSetup.create',
  CarrierSetup = 'carrierSetup',
  UpdateParcelShopBranchId = 'updateParcelShopBranchId',
  FeedMorpher = 'feedMorpher',
}

export const rolePermissions = {
  SALESCONNECT_ADMIN: [
    Permission.RevertOrderStatus,
    Permission.ProductImport,
    Permission.KauflandImport,
    Permission.AllegroImport,
    Permission.LabelCreation,
    Permission.UpdateStocksAndPrices,
    Permission.BatchUpdate,
    Permission.Orders,
    Permission.Shipments,
    Permission.Products,
    Permission.UserProfile,
    Permission.PartnerSetupOverview,
    Permission.PartnerSetup,
    Permission.EshopSetupOverview,
    Permission.EshopSetup,
    Permission.UserSetup,
    Permission.UserSetupCreate,
    Permission.CarrierSetup,
    Permission.UpdateParcelShopBranchId,
    Permission.FeedMorpher,
  ],
  PARTNER_ADMIN: [
    Permission.ProductImport,
    Permission.KauflandImport,
    Permission.AllegroImport,
    Permission.LabelCreation,
    Permission.UpdateStocksAndPrices,
    Permission.BatchUpdate,
    Permission.Orders,
    Permission.Shipments,
    Permission.Products,
    Permission.UserProfile,
    Permission.PartnerSetup,
    Permission.EshopSetup,
  ],
  PARTNER_USER: [
    Permission.ProductImport,
    Permission.KauflandImport,
    Permission.AllegroImport,
    Permission.LabelCreation,
    Permission.UpdateStocksAndPrices,
    Permission.BatchUpdate,
    Permission.Orders,
    Permission.Shipments,
    Permission.Products,
    Permission.UserProfile,
  ],
  PARTNER_WAREHOUSE_OPERATIONS: [
    Permission.Orders,
    Permission.Products,
    Permission.LabelCreation,
    Permission.Shipments,
  ],
};

export type UserWithToken = {
  user: AuthorizedUser
  token: string,
  expires: number,
};

export interface TokenPayload extends JwtPayload {
  user: User
}

interface ChangePasswordPayload {
  username: string;
  password: string;
  newPassword: string;
}

export interface UserStoreModel {
  userTokens: UserWithToken[];
  currentUser: AuthorizedUser;
  login: Thunk<UserStoreModel, { username: string, password: string }, any, StoreModel>
  changePassword: Thunk<UserStoreModel, ChangePasswordPayload, any, StoreModel>
  logout: Action<UserStoreModel>;
  restore: Action<UserStoreModel>;
  setTokens: Action<UserStoreModel, UserWithToken[]>;
  changePartner: Action<UserStoreModel, string>;
  setCurrentUser: Action<UserStoreModel, AuthorizedUser>;
  hasPermission: Computed<UserStoreModel, (requiredPermission: Permission) => boolean>;
}
