import React from 'react';
import { Nav } from 'rsuite';
import { Link } from 'react-router-dom';
import { PARTNER_SETUP } from '../../routes/links';
import { useStoreState } from '../../store/hooks';
import { Permission } from '../../store/user/types';

type Tab = 'mpi' | 'platform' | 'feed';

function getTo(tab: Tab): string {
  return `${PARTNER_SETUP}/${tab}`;
}

interface NavItemProps {
  tab: Tab;
  children: React.ReactNode;
}

function NavItem({ tab, children }: NavItemProps): React.ReactElement {
  return (
    <Nav.Item
      key={tab}
      eventKey={tab}
      as={Link}
      to={getTo(tab)}
    >
      {children}
    </Nav.Item>
  );
}

interface Props {
  tab: 'mpi' | 'platform' | 'feed';
}

export default function PartnerSetupNavigation({ tab } : Props) {
  const currentUser = useStoreState(state => state.user);

  return (
    <Nav activeKey={tab} appearance="subtle">
      <NavItem tab="mpi">MPI setup</NavItem>
      <NavItem tab="platform">Platform setup</NavItem>
      {currentUser.hasPermission(Permission.FeedMorpher) && <NavItem tab="feed">Feed sync</NavItem>}
    </Nav>
  );
}
